/* eslint-disable max-len */
import React, { Children } from 'react';
import Helmet from 'react-helmet';
import { keys } from 'lodash';

import { metaPropTypes } from 'config/propTypes';

import logo from 'assets/images/logos/selfstorage-social-logo.jpg';

export default function Head({
	description,
	title,
	canonical,
	meta,
	links,
	noIndex,
	noFollow,
}) {
	return (
		<Helmet>
			<title key="title">{title}</title>
			{!!description && (
				<meta
					key="description"
					name="description"
					content={description}
				/>
			)}
			{!!canonical && (
				<link
					rel="canonical"
					href={canonical}
				/>
			)}
			{!!noIndex && (
				<meta
					key="robots"
					name="robots"
					content={!noFollow ? 'noindex' : 'noindex,nofollow'}
				/>
			)}
			{keys(meta).map((key) => (
				<meta
					key={key}
					name={key}
					content={meta[key]}
				/>
			))}
			{Children.toArray(links.map((link) => <link {...link} />))}
			<link
				rel="icon"
				href={logo}
			/>
			<meta
				property="og:title"
				content={title}
			/>
			<meta
				property="og:description"
				content={description}
			/>
			<meta
				property="og:type"
				content="website"
			/>
			<meta
				property="og:image"
				content={logo}
			/>
			<meta
				property="og:url"
				content={canonical}
			/>
			<meta
				name="twitter:card"
				content="svg twitter"
			/>
			<meta
				name="twitter:title"
				content={title}
			/>
			<meta
				name="twitter:description"
				content={description}
			/>
			<meta
				name="twitter:image"
				content={logo}
			/>
		</Helmet>
	);
}

Head.propTypes = metaPropTypes;

Head.defaultProps = {
	title: 'SelfStorage.com: Find Cheap Storage Units Near You',
	description:
		'With over 40,000 storage facilities, SelfStorage.com helps you rent the cheapest self-storage units. Check local prices, amenities & book online.',
	links: [],
};
